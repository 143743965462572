import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Form, Input, Checkbox } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import AuthRepo from "../../repositores/authRepo/LoginRepo";
import RouteAdm from "../../services/routeAdm";
import RouteTalent from "../../services/RouteTalent";
import RouteName from "../../helper/routeName";
import LogoRepo from "../../repositores/adminRepo/LogoRepo";

const Login = () => {
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mounted, setMounted] = useState(true);
  const [logoGlobal, setLogoGlobal] = useState(null);

  useEffect(() => {
    getLogo();
    return () => {
      setMounted(false);
    };
  }, []);

  const getLogo = async () => {
    try {
      const response = await LogoRepo.getLogoNotToken();
      const logo = response.data?.data?.content;
      setLogoGlobal(logo);
    } catch (error) {
      console.log(error);
    }
  };

  const keyupEvent = (e) => {
    if (e.charCode === 13) {
      loginUser();
    }
  };

  const disableButton = () => {
    setDisable(true);
    Swal.fire({
      title: "Process",
      toast: true,
      text: "Please wait a moment...",
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: ({ access_token }) => checkGoogleLogin(access_token),
  });

  const checkGoogleLogin = async (accessToken) => {
    disableButton();
    try {
      const { data } = await AuthRepo.loginWithGoogle({ accessToken });
      if (mounted) {
        successLogin(data);
      }
    } catch (error) {
      if (mounted) {
        handleLoginError(error);
      }
      handleLoginError(error);
    } finally {
      if (mounted) {
        setDisable(false);
      }
    }
  };

  const successLogin = (data) => {
    if (data.status === 404) {
      if(!data.message?.is_enabled) {
        showErrorMessage("Login Failed", "Your Account Is Inactive, Please Contact Admin");
      }else {
        showErrorMessage("Login Failed", data.message);
      }
    }

    const res = data.data;
    localStorage.setItem("token", res.access_token);
    localStorage.setItem("refresh_token", res.refresh_token);
    localStorage.setItem("name", res.user.fullname);

    const role = res.user.roles[0];
    navigate(
      role.name === "ROLE_ADMIN" ? RouteAdm.dashboard : RouteTalent.dashboard
    );
  };

  const handleLoginError = (error) => {
    const err = error.response;
    if (err) {
      showErrorMessage("Error", err.message);
    }
  };

  const showErrorMessage = (title, message) => {
    Swal.fire({
      title,
      toast: true,
      text: message,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      color: "#DD0000",
    });
  };

  const isEmptyInput = () => {
    return email === "" || password === "";
  };

  const loginUser = async () => {
    if (isEmptyInput()) {
      showErrorMessage("Input Empty", "Email or password cannot be empty.");
      return;
    }

    disableButton();

    try {
      const { data } = await AuthRepo.loginUser({ email, password });
      
      if (mounted) {
        successLogin(data);
      }
    } catch (error) {
      if (mounted) {
        handleLoginError(error);
      }
      handleLoginError(error);
    } finally {
      if (mounted) {
        setDisable(false);
      }
    }
  };

  return (
    <>
      <div className="flex justify-center">
        {logoGlobal?.map((item) => (
          <img key={item.id} alt={item.id} src={item.name} className="mb-8" />
        ))}
      </div>
      <div className="text-xl md:text-3xl font-bold text-[#4C4C4C] mb-4">
        Login
      </div>
      <div className="text-xs md:text-base text-[#B4B4B4] mb-5">
        Welcome back! Please enter your details.
      </div>
      {/* main */}
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item name="email" label="Email" className="mb-3 font-medium">
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            required
            onKeyUp={(e) => keyupEvent(e)}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          className="mb-3 font-medium"
        >
          <Input.Password
            value={password}
            required
            placeholder="Enter your password"
            onKeyUp={(e) => keyupEvent(e)}
            onChange={(e) => setPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <div className="flex justify-between items-center mb-6">
          <Checkbox>
            <span className="font-medium">Remember me</span>
          </Checkbox>
          <div
            className="text-[#DD0000] font-bold cursor-pointer"
            onClick={() => navigate(RouteName.forgotPassword)}
          >
            Forgot Password?
          </div>
        </div>
        <Form.Item>
          <button
            onClick={() => loginUser()}
            className={`w-full bg-[#DD0000] py-2 rounded-md text-white font-semibold ${
              disable ? "pointer-events-none opacity-50" : ""
            }`}
          >
            Log in
          </button>
        </Form.Item>
        <Form.Item>
          <div
            className="bg-white rounded p-2 cursor-pointer relative"
            onClick={() => loginGoogle()}
          >
            <img
              className="absolute left-2"
              src="/assets/icons/google.svg"
              alt="logo-google"
              width={20}
            />
            <div className="text-center font-medium"> Sign in with Google</div>
          </div>
        </Form.Item>
      </Form>
      {/* end main */}
    </>
  );
};

export default Login;
