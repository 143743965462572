import React from "react";
import { Table } from "antd";
import SkeletonTable from "../master/Events/components/SkeletonTable";

const TableInterviewCandidate = ({ dataSource, columns, isLoading }) => {
  if (isLoading) {
    return <SkeletonTable />;
  }
  return (
    <div className="">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        // sticky={true}
        rowKey="id"
      />
    </div>
  );
};

export default TableInterviewCandidate;
