import CallApi from "../../services/Request-Helper";
import { createSearchParams } from "../../utils/utils";
const API_BASE = `${process.env.REACT_APP_BASE}api/v1`;

const getListOnboarding = async (page = 0, size = 10, name = undefined) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["name"] = name;
  }
  const url = `${API_BASE}/onboard-sop/list-user?${createSearchParams(params)}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getListOneMonthSupervised = async (
  page = 0,
  size = 10,
  name = undefined
) => {
  let params = {
    page: page + 1,
    size: size,
  };
  if (typeof name != "undefined" && name.length) {
    params["clientName"] = name;
  }
  const url = `${API_BASE}/one-month-supervised/list-user?${createSearchParams(
    params
  )}`;

  const response = await CallApi({ url, method: "GET" });
  return response;
};

const getFile = async (fileName) => {
  let url = `${API_BASE}/showFile/${fileName}`;
  // const response = await CallApi({ url, method: "GET"  });
  return url;
};

const editOMS = async (body) => {
  const url = `${API_BASE}/one-month-supervised/update-user`;
  const response = await CallApi({ url, method: "PUT", data: body });
  return response;
};

const addOrUpdateOms = async (body) => {
  const url = `${API_BASE}/one-month-supervised/add-update-talent`;
  const response = await CallApi({ url, method: "POST", data: body });
  return response;
};

const sendEmail = async (id) => {
  const url = `${API_BASE}/one-month-supervised/send-email-reminder/${id}`;
  const response = await CallApi({ url, method: "POST" });
  return response;
};

const Onboarding = {
  getListOnboarding,
  getFile,
  getListOneMonthSupervised,
  editOMS,
  addOrUpdateOms,
  sendEmail,
};

export default Onboarding;
