import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Pagination, Select } from "antd";
import InterviewClientRepo from "../../../repositores/adminRepo/InterviewClientRepo";
import MasterUserManagementRepo from "../../../repositores/adminRepo/UserManagement";
import TableInterviewCandidate from "../componets_global/TableInterviewCandidate";
import InterviewCandidateRepo from "../../../repositores/adminRepo/InterviewCandidateRepo";

const { Option } = Select;

const ModalInterviewClient = ({
  openModal,
  onClick,
  title,
  getData,
  dataEdit,
  instanceForm,
}) => {
  const [selectedRole, setSelectedRole] = useState(
    dataEdit?.retroInterviewClient?.role?.id || []
  );
  const [selectedRetro, setSelectedRetro] = useState(
    dataEdit?.retroInterviewClient?.id || []
  );

  const [listRole, setListRole] = useState([]);
  const [listRetro, setListRetro] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElementRole, setTotalElementRole] = useState(-1);
  const [totalElementRetro, setTotalElementRetro] = useState(-1);
  const [mounted, setMounted] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [listData, setListData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [selectedType, setSelectedType] = useState(dataEdit?.type || "");
  const limit = 10;

  useEffect(() => {
    instanceForm.setFieldsValue({
      candidateName: dataEdit?.candidateName,
      talent: dataEdit?.talent?.fullname,
      interviewerName: dataEdit?.interviewerName,
      retroInterviewClient: dataEdit?.retroInterviewClient?.interviewerName && dataEdit?.retroInterviewClient?.role?.name && dataEdit?.retroInterviewClient?.account?.name ?  `${dataEdit?.retroInterviewClient?.interviewerName} - ${dataEdit?.retroInterviewClient?.role?.name} - ${dataEdit?.retroInterviewClient?.account?.name}` : null,
      status: dataEdit?.status,
      type: dataEdit?.type,
    });
    setSelectedRole(dataEdit?.role?.id);
    setSelectedRetro(dataEdit?.retroInterviewClient?.id);
  }, [dataEdit, instanceForm, title]);

  useEffect(() => {
    setSelectedType((prev) => dataEdit?.type);
  }, [dataEdit?.type]);

  useEffect(() => {
    const fetchDetailRetroInterviewClient = async () => {
      if (openModal && selectedRetro) {
        const response =
          await InterviewClientRepo.getListDetailRetroInterviewClient(
            page,
            limit,
            selectedRetro
          );
        setListData(response.data?.data?.content || []);
        setTotalItem(response.data?.data?.totalElements || 0);
        setLoadingTable(false);
      } else {
        setListData([]);
        setTotalItem(0);
        setLoadingTable(false);
      }
    };

    fetchDetailRetroInterviewClient();
  }, [openModal, page, selectedRetro]);

  useEffect(() => {
    if (!mounted && openModal) {
      handleFetchMoreRole(page, 20);
      handleFetchMoreRetro(page, 20);
    }
    return () => {
      setMounted(true);
    };
  }, [mounted, page, openModal]);

  const handleScrollEventRole = (event) => {
    event.stopPropagation();
    if (!loadingMore) {
      handleFetchMoreRole(page, 20);
    }
  };

  const handleScrollEventRetro = (event) => {
    event.stopPropagation();
    if (!loadingMore) {
      handleFetchMoreRetro(page, 20);
    }
  };

  const handleSearchRole = async (value) => {
    setLoadingMore(true);
    if (value === "") {
      await handleFetchMoreRole(0, 10);
    } else {
      const response = await MasterUserManagementRepo.getUserManagement(
        0,
        10,
        value
      );
      const tempOptions = response.data?.data?.content || [];
      setListRole(tempOptions);
      setPage(0);
    }
    setLoadingMore(false);
  };

  const handleSearchRetro = async (value) => {
    setLoadingMore(true);
    if (value === "") {
      await handleFetchMoreRetro(0, 10);
    } else {
      const response = await InterviewClientRepo.getListInterviewClient(
        0,
        10,
        value
      );
      const tempOptions = response.data?.data?.content || [];
      setListRetro(tempOptions);
      setPage(0);
    }
    setLoadingMore(false);
  };

  const handleFetchMoreRole = async (page, limit) => {
    if (listRole.length < totalElementRole || totalElementRole === -1) {
      const response = await MasterUserManagementRepo.getUserManagement(
        page,
        limit
      );
      const tempOptions = response?.data?.data?.content || [];
      setListRole((prevOptions) => [...prevOptions, ...tempOptions]);
      setLoadingMore(false);
      setTotalElementRole(response.data?.data?.totalElements);
      setPage(page + 1);
    }
  };

  const handleFetchMoreRetro = async (page, limit) => {
    if (listRetro.length < totalElementRetro || totalElementRetro === -1) {
      const response = await InterviewClientRepo.getListInterviewClient(
        page,
        limit
      );
      const tempOptions = response?.data?.data?.content || [];
      setListRetro((prevOptions) => [...prevOptions, ...tempOptions]);
      setLoadingMore(false);
      setTotalElementRetro(response.data?.data?.totalElements);
      setPage(page + 1);
    }
  };

  const onChangePage = (page) => {
    setPage(page - 1);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (_, __, index) => <div>{index + 1}</div>,
    },
    {
      title: "Question",
      dataIndex: ["retroInterviewClientDetail", "question"],
      key: "key",
      align: "left",
      width: '40%',
      render: (v,row) => {
        return <div>{v}</div>
      },
    },
    {
      title: "Course Name",
      dataIndex: ["retroInterviewClientDetail", "course", "name"],
      key: "course",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Mata Kuliah",
      dataIndex: ["retroInterviewClientDetail", "coursesMatkull", "matkul"],
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "id",
      align: "center",
      render: (v) => {
        return <p>{v !== null ? v : "-"}</p>;
      },
    },
    {
      title: "Do Understand",
      dataIndex: "isTalentUnderstand",
      key: "position",
      align: "center",
      render: (v, row) => {
        return <Checkbox checked={row?.isTalentUnderstand} disabled />;
      },
    },
  ];

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  return (
    <Modal
      open={openModal}
      onOk={onClick}
      onCancel={onClick}
      footer={null}
      width="60%"
      centered
    >
      <div>
        <p className="text-[20px] font-[500] text-[#4C4C4C]">
          {title} Interview Candidate
        </p>
        <p className="text-[12px] font-[500] text-[#A8A8A8] mb-[40px]">
          Enter your data below!
        </p>
        <Form
          name={title}
          form={instanceForm}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 6 }}
          style={{ width: '100%' }}
          onFinish={(props) =>
            getData("success", props, selectedRetro, selectedRole)
          }
          onFinishFailed={(props) =>
            getData("failed", props, selectedRetro, selectedRole)
          }
          autoComplete="off"
        >
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: "Enter Type",
              },
            ]}
          >
            <Select placeholder="Select type" onChange={handleTypeChange}>
              <Option value="TALENT">TALENT</Option>
              <Option value="CANDIDATE">CANDIDATE</Option>
            </Select>
          </Form.Item>
          {(selectedType && selectedType !== "TALENT") && (
            <Form.Item
              label="Candidate Name"
              name="candidateName"
              rules={[
                {
                  required: true,
                  message: "Enter Candidate Name",
                },
              ]}
            >
              <Input
                placeholder="Input Candidate Name Here"
                disabled={selectedType === "TALENT"}
              />
            </Form.Item>
          )}
          {(selectedType && selectedType !== "CANDIDATE") && (
            <Form.Item
              label="Talent Name"
              name="talent"
              rules={[
                {
                  required: true,
                  message: "Enter Talent Name",
                },
              ]}
            >
              <Select
                className="w-full"
                value={selectedRole}
                onChange={(val) => {
                  setSelectedRole(val);
                }}
                showSearch
                placeholder="Choose a Candidate Name"
                loading={loadingMore}
                onSearch={handleSearchRole}
                optionFilterProp="children"
                dropdownRender={(menu) => (
                  <div onWheel={handleScrollEventRole}>{menu}</div>
                )}
              >
                {listRole.map((role, index) => {
                  return (
                    <Option key={`listRole${index}`} value={role.id}>
                      {role.fullname}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Account Name"
            name="retroInterviewClient"
            rules={[
              {
                required: true,
                message: "Enter Account Name",
              },
            ]}
          >
            <Select
              className="w-full"
              value={selectedRetro}
              onChange={(val) => {
                setSelectedRetro(val);
              }}
              showSearch
              placeholder="Choose a Account Name"
              loading={loadingMore}
              onSearch={handleSearchRetro}
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <div onWheel={handleScrollEventRetro}>{menu}</div>
              )}
            >
              {listRetro.map((retro, index) => {
                return (
                  <Option key={`listRetro${index}`} value={retro.id}>
                    {retro.interviewerName} - {retro?.role?.name} -&nbsp;
                    {retro?.account?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Interviewer Name"
            name="interviewerName"
            rules={[
              {
                required: true,
                message: "Enter Interviewer Name",
              },
            ]}
          >
            <Input placeholder="Input Retro Interview Here" />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "Enter Status",
              },
            ]}
          >
            <Select placeholder="Select status">
              <Option value="NEW">NEW</Option>
              <Option value="PASSED">PASSED</Option>
              <Option value="FAILED">FAILED</Option>
              <Option value="CANCELED">CANCELED</Option>
            </Select>
          </Form.Item>
          <h3 className="mb-3">List Retro Question</h3>
          <TableInterviewCandidate
            dataSource={dataEdit?.retroInterviewCandidateDetails}
            columns={columns}
            isLoading={loadingTable}
          />
          <div className="w-full flex justify-center my-5">
            <Pagination
              current={page + 1}
              onChange={onChangePage}
              pageSize={limit}
              total={totalItem}
              showSizeChanger={false}
            />
          </div>
          {title.toLowerCase() !== "view" ? (
            <Form.Item>
              <div className="flex justify-center">
                <Button
                  className="bg-red-500 radius-[7px] w-40 h-[30px]"
                  style={{ backgroundColor: "red" }}
                  type="primary"
                  htmlType="submit"
                >
                  <p className="text-[12px] font-[500] text-white">
                    {dataEdit ? "Update" : "Create"}
                  </p>
                </Button>
              </div>
            </Form.Item>
          ) : null}
        </Form>
      </div>
    </Modal>
  );
};

export default ModalInterviewClient;
