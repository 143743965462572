import { createDefaultRouteForJourney } from "../../../modules/journey/pages/router";
import RouteTalent from "../../../services/RouteTalent";
import {
  CertificateIcon,
  DashboardIcon,
  HistoryIcon,
  JourneyIcon,
  LeaderboardIcon,
  RewardsIcon,
} from "./icons/icons";

export const stateColor = {
  active: "#DD0000",
  inactive: "#4C4C4C",
};

export const sideBarMenu = {
  dashboard: {
    title: "Dashboard",
    src: "dashboard",
    route: "/talent/dashboard",
    hasChild: false,
    color: "#4C4C4C",
    active: true,
    icon: (className, color) => {
      return <DashboardIcon className={className} color={color} />;
    },
  },
  leaderboard: {
    title: "Leaderboard",
    src: "leaderboard",
    route: "/talent/leaderboard",
    hasChild: false,
    color: "white",
    active: false,
    icon: (className, color) => {
      return <LeaderboardIcon className={className} color={color} />;
    },
  },
  certificates: {
    title: "Certificates",
    src: "certif",
    route: "/talent/certificate",
    hasChild: false,
    color: "white",
    active: false,
    icon: (className, color) => {
      return <CertificateIcon className={className} color={color} />;
    },
  },
  rewards: {
    title: "Rewards",
    src: "rewards",
    route: "/talent/rewards",
    hasChild: false,
    color: "white",
    active: false,
    icon: (className, color) => {
      return <RewardsIcon className={className} color={color} />;
    },
  },
  journey: {
    title: "Journey",
    src: "journey",
    route: createDefaultRouteForJourney(),
    hasChild: false,
    color: "white",
    active: false,
    icon: (className, color) => {
      return <JourneyIcon className={className} color={color} />;
    },
  },
  history: {
    title: "History",
    src: "redeem",
    route: "/talent/history",
    hasChild: false,
    color: "white",
    active: "false",
    icon: (className, color) => {
      return <HistoryIcon className={className} color={color} />;
    },
  },
  matchingSkill: {
    title: "Matching Skills",
    src: "clientHistory",
    route: "",
    hasChild: true,
    Childs: [
      {
        title: "Roles",
        src: "yourRole",
        route: RouteTalent.yourRole,
      },
      {
        title: "Skills",
        src: "yourSkill",
        route: RouteTalent.yourSkill,
      },
      {
        title: "Client History",
        src: "clientHistory",
        route: RouteTalent.historyProject,
      },
      {
        title: "Talent Issue",
        src: "talentIssue",
        route: RouteTalent.talentIssue,
      },
      {
        title: "Placement Talent",
        src: "talentIssue",
        route: RouteTalent.placementHistory,
      },
      {
        title: "Checklist Skill Interview",
        src: "checklistSkill",
        route: RouteTalent.checklistSkill,
      },
    ],
    color: "white",
    active: "false",
    icon: (className, color) => {
      return <HistoryIcon className={className} color={color} />;
    },
  },
  // add by iqbal ravi
  talentJourney: {
    title: "Talent Journey",
    src: "talentJourney",
    route: "/talent/talent-journey",
    hasChild: false,
    color: "white",
    active: "false",
    icon: (className, color) => {
      return <JourneyIcon className={className} color={color} />;
    },
  },

  talentOnboarding: {
    title: "Onboarding",
    src: "onboarding",
    route: "",
    hasChild: true,
    Childs: [
      {
        title: "Onboarding",
        src: "talentOnboarding",
        route: RouteTalent.talentOnboarding,
      },
      {
        title: "1 Month Supervise",
        src: "talent1MSupervise",
        route: RouteTalent.talentOneMonthSupervised,
      },
    ],
    color: "white",
    active: "false",
    icon: (className, color) => {
      return <JourneyIcon className={className} color={color} />;
    },
  }
};
