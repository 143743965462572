import React, { useState, useEffect } from "react";
import { Pagination, Table } from "antd";
import ModalEdit from "./ModalEdit";
import { COLUMNS_MANAGEMENT } from "./columns.state";
import AlertUtility from "../../../../components/Alert/Alert";
import SkeletonTable from "../../../../components/general/SkeletonTable";
import MasterUserManagementRepo from "../../../../repositores/adminRepo/UserManagement";

const UserManagement = () => {
  const [loading, setLoading] = useState(false);
  const [currentTalent, setCurrentTalent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [dataUser, setDataUser] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const closeModal = (refresh) => {
    setShowModal(false);
    if (refresh) {
      getDataUser(page);
    }
  };

  const onClickEdit = (record) => {
    setCurrentTalent(record);
    setShowModal(true);
  };

  const getDataUser = async (pageNumber = 0) => {
    try {
      setLoading(true);
      const pageSize = 10;
      const { data } = await MasterUserManagementRepo.getUserManagement(
        pageNumber,
        pageSize,
        search
      );
      const result = data.data.content.map((user) => {
        return {
          key: user.id,
          image: user.fullname,
          name: user.fullname,
          email: user.username,
          status: user.status,
          role: user.roles[0].name,
          action: "",
          roleId: user.roles[0].id,
        };
      });
      setPage(data.data.number);
      setDataUser(result);
      setTotalData(data.data.totalElements);
    } catch (error) {
      setPage(0);
      setDataUser(0);
      setDataUser([]);
      AlertUtility.ErrorAlert({ title: error.response?.message });
    } finally {
      setLoading(false);
    }
  };

  const changePagination = (pageNumber) => {
    getDataUser(pageNumber - 1);
  };

  const renderColumnsButton = () => {
    columns[5].render = (_, record) => (
      <div className="flex justify-center">
        <div
          onClick={() => onClickEdit(record)}
          className="w-[76px] text-center rounded bg-primary text-white font-semibold py-1 cursor-pointer"
        >
          Edit
        </div>
      </div>
    );
  };

  useEffect(() => {
    getDataUser();
    renderColumnsButton();
  }, [search]);

  const columns = COLUMNS_MANAGEMENT;

  return (
    <>
      <div className="flex justify-end w-full mb-4">
        <input
          type="text"
          placeholder="Search user..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="border rounded-md w-full lg:w-[350px] py-1 px-2"
        />
      </div>
      {loading ? (
        <SkeletonTable />
      ) : (
        <>
          <div className="w-full mb-5">
            <Table
              columns={columns}
              dataSource={dataUser}
              pagination={false}
              sticky={true}
              scroll={{
                x: 1000,
                y: 450,
              }}
            />
          </div>
          <ModalEdit
            show={showModal}
            closeModal={closeModal}
            talent={currentTalent}
          />
          <Pagination
            current={page + 1}
            total={totalData}
            showSizeChanger={false}
            onChange={(p) => changePagination(p)}
            className="flex justify-center"
          />
        </>
      )}
    </>
  );
};

export default UserManagement;
