// ROUTE TALENT
const dashboard = "/talent/dashboard";
const reward = "/talent/rewards";
const certificate = "/talent/certificate";
const certificateDetail = "/talent/certificate/details";
const leaderboard = "/talent/leaderboard";
const coinHistory = "/talent/history";
const historyProject = "/talent/client-history";
const placementHistory = "/talent/placement-history";
const talentIssue = "/talent/talent-issue";
const yourRole = "/talent/role";
const yourSkill = "/talent/skill";
const checklistSkill = "/talent/checklist"; // checklist skill interview

// add by iqbal ravi
const talentJourney = "/talent/talent-journey";
const talentJourneyTermin = "/talent/talent-journey/termin";
const talentJourneyTopic = "/talent/talent-journey/topic";

const talentOnboarding = "/talent/onboarding";
const talentOneMonthSupervised = "/talent/one-month-supervised";
const talentTLOneMonthSupervised = "/talent/tl-one-month-supervised";

const RouteTalent = {
  dashboard,
  reward,
  certificate,
  leaderboard,
  certificateDetail,
  coinHistory,
  historyProject,
  talentIssue,
  yourRole,
  yourSkill,
  placementHistory,
  talentJourney, // add by iqbal ravi
  talentJourneyTermin, // add by iqbal ravi
  talentJourneyTopic, // add by iqbal ravi
  checklistSkill,
  talentOnboarding,
  talentOneMonthSupervised,
  talentTLOneMonthSupervised,
};

export default RouteTalent;
