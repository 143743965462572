// ROUTE ADMIN
const dashboard = "/admin/dashboard";
const leaderboard = "/admin/leaderboard";
const userManagement = "/admin/user-management";
const masterEvent = "/admin/master-event";
const event = "/admin/event";
const task = "/admin/datamaster/task";
const addTask = "/admin/datamaster/addTask";
const certificateTemplate = "/admin/certificate-template";
const badges = "admin/badges";
const certificateIssued = "/admin/certificate-issued";
const redeemHistory = "/admin/redeem-history";
const reportFridayBooster = "/admin/report-friday-booster";
const rewards = "admin/rewards";
const journey = "admin/journey";
const eventDetail = "/admin/event/:id";
const statusTalent = "/admin/status-talent";
const placementTalentHistory = "/admin/placement-talent-history";
const checklistInterviewClient = "/admin/checklist-interview-client";
const checklistInterviewCandidate = "/admin/checklist-interview-candidate";
const clientDataMaster = "/admin/client"
const roleDataMaster = "/admin/role"
const admLmsCourse = "/admin/course"
const admLmsMataKuliah = "/admin/course/:id"
const admLmsSubMataKuliah = "/admin/course/:id/mata-kuliah/:matkulId"
const admLmsDetailSubMataKuliah = "/admin/course/:id/mata-kuliah/:matkulId/sub/:subMatkulId"
const admLmsHistories = "/admin/history-course"
const admLmsDetailHistories = "/admin/history-course/:courseId/:userId"
const admOnboarding = "/admin/onboarding"
const admOMS = "/admin/one-month-supervised"
const admDetailOMS = "/admin/one-month-supervised/:id"
const admClientHistory = "/admin/client-history";

const RouteAdm = {
  dashboard,
  leaderboard,
  masterEvent,
  task,
  addTask,
  certificateTemplate,
  badges,
  certificateIssued,
  event,
  userManagement,
  redeemHistory,
  reportFridayBooster,
  rewards,
  journey,
  eventDetail,
  statusTalent,
  placementTalentHistory,
  checklistInterviewClient,
  checklistInterviewCandidate,
  clientDataMaster,
  roleDataMaster,
  admLmsCourse,
  admLmsMataKuliah,
  admLmsSubMataKuliah,
  admLmsHistories,
  admLmsDetailHistories,
  admLmsDetailSubMataKuliah,
  admOnboarding,
  admOMS,
  admDetailOMS,
  admClientHistory
};

export default RouteAdm;
