import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Pagination,
  message,
  Popconfirm,
} from "antd";
import InterviewClientRepo from "../../../repositores/adminRepo/InterviewClientRepo";
import TableInterviewCandidate from "../componets_global/TableInterviewCandidate";
import { useForm } from "antd/es/form/Form";
import RetroQuestionForm from "./RetroQuestionForm";
import { debounce } from "lodash";

const { Option } = Select;

const ModalInterviewClient = ({
  openModal,
  onClick,
  title,
  getData,
  dataEdit,
  instanceForm,
}) => {
  const [selectedRole, setSelectedRole] = useState(dataEdit?.role?.id || []);
  const [selectedAccount, setSelectedAccount] = useState(
    dataEdit?.account?.id || []
  );
  const [listRole, setListRole] = useState([]);
  const [listAccount, setListAccount] = useState([]);
  const [listCourse, setListCourse] = useState([]);
  const [listMatkul, setListMatkul] = useState([]);
  const [page, setPage] = useState(1);
  const [pageRole, setPageRole] = useState(1);
  const [pageAccount, setPageAccount] = useState(1);
  const [pageCourse, setPageCourse] = useState(1);

  const [loadingRole, setLoadingRole] = useState(false);
  const [loadingMoreAccount, setLoadingAccount] = useState(false);
  const [loadingMoreCourse, setLoadingCourse] = useState(false);

  const [totalElementRole, setTotalElementRole] = useState(-1);
  const [totalElementAccount, setTotalElementAccount] = useState(-1);
  const [totalElementCourse, setTotalElementCourse] = useState(-1);

  const [pageMatkul, setPageMatkul] = useState(0);
  const [totalElementMatkul, setTotalElementMatkul] = useState(-1);
  const [loadingSearchMatkul, setLoadingSearchMatkul] = useState(false);
  const [loadingMoreMatkul, setLoadingMatkul] = useState(false);
  const [bounchMatkul, setBounchMatkul] = useState(undefined)
  const [searchMatkul, setSearchMatkul] = useState(undefined)
  const [idCourse, setIdCourse] = useState(undefined)

  const [selectedMatkul, setSelectedMatkul] = useState(undefined)

  const [mounted, setMounted] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [pendingChanges, setPendingChanges] = useState([]);
  const [listData, setListData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const limit = 10;

  const [questionOpen, setQuestionOpen] = useState(false);
  const [formQuestion] = Form.useForm();
  const [recordId, setRecordId] = useState(null);
  const [questionEdit, setQuestionEdit] = useState("new"); // false new / true edit
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [messageApi, contextHolder] = message.useMessage();

  const [keysRetro, setKeysRetro] = useState(1)

  useEffect(() => {
    formQuestion.resetFields();
  }, [questionOpen]);

  useEffect(() => {
    instanceForm.setFieldsValue({
      role: dataEdit?.role?.name,
      account: dataEdit?.account?.name,
      retro: dataEdit?.retro,
      picTa: dataEdit?.picTa,
      interviewerName: dataEdit?.interviewerName,
      projectName: dataEdit?.projectName,
    });
    setSelectedRole(dataEdit?.role?.id);
    setSelectedAccount(dataEdit?.account?.id);
  }, [dataEdit, instanceForm, title]);

  useEffect(() => {
    // setPage((prev) => -1);
    if (title.toLowerCase() === "edit") {
      const fetchDetailRetroInterviewClient = async () => {
        if (openModal) {
          const response =
            await InterviewClientRepo.getListDetailRetroInterviewClient(
              page,
              limit,
              dataEdit?.id
            );
          const data = response.data?.data?.content || [];
          const dataWithKeys = data.map((item, index) => ({
            ...item,
            key: item.id || index,
          }));
          setListData(dataWithKeys);
          setTotalItem(response.data?.data?.totalElements || 0);
          setLoadingTable(false);
        } else {
          setListData([]);
          setTotalItem(0);
          setLoadingTable(false);
        }
      };
      fetchDetailRetroInterviewClient();
    }
  }, [dataEdit?.id, openModal, page]);

  const fetchDetailRetroInterviewClient = async () => {
    
    if (openModal) {
      const response =
        await InterviewClientRepo.getListDetailRetroInterviewClient(
          page,
          limit,
          dataEdit?.id
        );
      const data = response.data?.data?.content || [];
      const dataWithKeys = data.map((item, index) => ({
        ...item,
        key: item.id || index,
      }));
      setListData(dataWithKeys);
      setTotalItem(response.data?.data?.totalElements || 0);
      setLoadingTable(false);
    } else {
      setListData([]);
      setTotalItem(0);
      setLoadingTable(false);
    }
  };

  const handleQuestion = async ({
    retroId = "",
    courseId = "",
    matkulId,
    question,
    noQuestion,
    id = "",
  }) => {
    let body = {
      //id: "15db9e36-462e-4a64-8b73-705b952bd31f", // jika add id nya set null, jika edit : set id nya
      retroInterviewClient: {
        id: retroId,
      },
      course: {
        id: courseId,
      },
      coursesMatkull: {
        id: matkulId,
      },
      noQuestion,
      question,
    };
    await InterviewClientRepo.editQuestionClient(
      id !== "" ? { id, ...body } : body
    )
      .then((res) => {
        if(res?.data?.status === 200) {
          messageApi.open({
            type: "success",
            content: id !== "" ? "Successfully edited" : "Successfully created",
          });
          fetchDetailRetroInterviewClient();
        } else if (res?.data?.status === 403) {
          messageApi.open({
            type: "error",
            content: res?.data?.message,
          });
        } else {
          messageApi.open({
            type: "error",
            content: "Internal server error",
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setQuestionOpen(false);
        setRecordId(null);
        formQuestion.resetFields();
      });
  };

  const handleDeleteQuestion = async (id) => {
    await InterviewClientRepo.deleteQuestionClient(id)
      .then(() => {
        messageApi.open({
          type: "success",
          content: "Successfully deleted",
        });
      })
      .catch((err) => {
        messageApi.error({
          type: "error",
          content: "Deleting failed",
        });
      })
      .finally(() => {
        fetchDetailRetroInterviewClient();
      });
  };

  useEffect(() => {
    if (!mounted && openModal) {
      handleFetchMoreRole(0, 20);
      handleFetchMoreAccount(0, 20);
      handleFetchMoreCourse(0, 20);
      setMounted(true);
    }
  }, [mounted, openModal]);

  const handleScrollEventRole = (event) => {
    event.stopPropagation();
    if (!loadingRole) {
      setLoadingRole(true);
      setPageRole((prevPage) => prevPage + 1);
      handleFetchMoreRole(pageRole, 20);
    }
  };

  const handleScrollEventAccount = (event) => {
    event.stopPropagation();
    if (!loadingMoreAccount) {
      setLoadingAccount(true);
      setPageAccount((prevPage) => prevPage + 1);
      handleFetchMoreAccount(pageAccount, 20);
    }
  };

  const handleScrollEventCourse = (event) => {
    event.stopPropagation();
    if (!loadingMore) {
      handleFetchMoreCourse(pageCourse, 20);
    }
  };

  const handleSearchRole = async (value) => {
    setLoadingRole(true);
    if (value === "") {
      await handleFetchMoreRole(0, 10);
    } else {
      const response = await InterviewClientRepo.getListRole(0, 10, value);
      const tempOptions = response.data?.data?.content || [];
      setListRole(tempOptions);
      setPageRole(0);
      
    }
    setLoadingRole(false);
  };

  const handleSearchAccount = async (value) => {
    setLoadingAccount(true);
    if (value === "") {
      await handleFetchMoreAccount(0, 10);
    } else {
      const response = await InterviewClientRepo.getListAccount(0, 10, value);
      const tempOptions = response.data?.data?.content || [];
      setListAccount(tempOptions);
      setPageAccount(0);
    }
    setLoadingAccount(false);
  };

  const handleSearchCourse = async (value, key) => {
    setLoadingMore((prev) => ({ ...prev, [key]: true }));
    if (value === "") {
      await handleFetchMoreCourse(0, 10);
    } else {
      const response = await InterviewClientRepo.getListCourse(0, 10, value);
      const tempOptions = response.data?.data?.content || [];
      setListCourse(tempOptions);
      setPageCourse(0);
    }
    setLoadingMore((prev) => ({ ...prev, [key]: false }));
  };

  const handleFetchMoreRole = async (page, limit) => {
    if (listRole.length < totalElementRole || totalElementRole === -1) {
      const response = await InterviewClientRepo.getListRole(page, limit);
      const tempOptions = response?.data?.data?.content || [];
      setListRole((prevOptions) => [...prevOptions, ...tempOptions]);
      setLoadingRole(false);
      setTotalElementRole(response.data?.data?.totalElements);
      
    }
  };


  const handleSearchMatkul = async (value, key) => {
    if(!loadingSearchMatkul) {
      setLoadingSearchMatkul(true)
      clearTimeout(bounchMatkul)
      setSearchMatkul(value)
      setBounchMatkul(setTimeout(async () => {
        const response = await InterviewClientRepo.getListMataKuliah(0, 20, idCourse,  searchMatkul);
        const tempOptions = response.data?.data?.content || [];
        setListCourse(tempOptions);
        setPageMatkul(0);
        setLoadingSearchMatkul(false);
      }, 2000))
    }
  };

  const handleScrollEventMatkul = async (event) => {
    event.stopPropagation();
    if (!loadingMoreMatkul) {
      if (listMatkul.length < totalElementMatkul || totalElementMatkul === -1) {
        setLoadingMatkul(true);
        setPageMatkul((prevPage) => prevPage + 1);
        const response = await InterviewClientRepo.getListMataKuliah(pageMatkul, 20, idCourse,  searchMatkul);
        const tempOptions = response?.data?.data?.content || [];
        setListMatkul((prevOptions) => [...prevOptions, ...tempOptions]);
        setTotalElementMatkul(response.data?.data?.totalElements);
        setLoadingMatkul(false);
      }
    }
  };

  const handleSelectedCourse = async (val) => {
    setIdCourse(val)
    setLoadingMatkul(true);
    setListMatkul([])
    setPageMatkul(0);
    setTotalElementMatkul(-1);
    setSearchMatkul("")
    setSelectedMatkul(undefined)
    const response = await InterviewClientRepo.getListMataKuliah(0, 20, val, searchMatkul);
    const tempOptions = response?.data?.data?.content || [];
    setListMatkul((prevOptions) => [...prevOptions, ...tempOptions]);
    setTotalElementMatkul(response.data?.data?.totalElements);
    setLoadingMatkul(false);
  }

  const handleFetchMoreAccount = async (page, limit) => {
    if (
      listAccount.length < totalElementAccount ||
      totalElementAccount === -1
    ) {
      const response = await InterviewClientRepo.getListAccount(page, limit);
      const tempOptions = response?.data?.data?.content || [];
      setListAccount((prevOptions) => [...prevOptions, ...tempOptions]);
      setLoadingAccount(false);
      setTotalElementAccount(response.data?.data?.totalElements);
    }
  };

  const handleFetchMoreCourse = async (page, limit) => {
    if (listCourse.length < totalElementCourse || totalElementCourse === -1) {
      const response = await InterviewClientRepo.getListCourse(page, limit);
      const tempOptions = response?.data?.data?.content || [];
      setListCourse((prevOptions) => [...prevOptions, ...tempOptions]);
      setLoadingCourse(false);
      setTotalElementCourse(response.data?.data?.totalElements);
      setPageCourse((prevPage) => prevPage + 1);
    }
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const handleInputChange = (e, key, field) => {
    const { value } = e.target;
    const updatedData = listData.map((item) => {
      if (item.key === key) {
        const updatedRow = { ...item, [field]: value };
        const existingChangeIndex = pendingChanges.findIndex(
          (change) => change.key === key
        );
        if (existingChangeIndex !== -1) {
          const updatedPendingChanges = [...pendingChanges];
          updatedPendingChanges[existingChangeIndex] = updatedRow;
          setPendingChanges(updatedPendingChanges);
        } else {
          setPendingChanges([...pendingChanges, updatedRow]);
        }
        return updatedRow;
      }
      return item;
    });
    setListData(updatedData);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (_, row, index) => <div>{row.noQuestion}</div>,
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      align: "center",
    },
    {
      title: "Course Name",
      dataIndex: ["course", "name"],
      key: "course",
      align: "center",
    },
    {
      title: "Mata Kuliah",
      dataIndex: ["coursesMatkull", "matkul"],
      key: "coursesMatkull",
      align: "center",
      render: (v) => <p>{v !== null ? v : "-"}</p>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (v, row) => {
        return (
          <div className="flex gap-3">
            <button
              className="bg-red-500 text-white w-[100px] py-1  rounded ms-3"
              onClick={(e) => {
                e.preventDefault();
                // handleEdit(row.id);
                setKeysRetro((prevVal) => prevVal + 1)
                setRecordId(() => row?.id);
                setIdCourse(row?.course?.id);
                handleSelectedCourse(row?.course?.id)
                setSelectedMatkul(row?.coursesMatkull)
                setQuestionOpen(() => true);
                setQuestionEdit(() => "edit");
              }}
            >
              Edit
            </button>
            <Popconfirm
              title="Delete the task"
              description={`Are you sure to delete question number ${row.noQuestion}?`}
              onConfirm={() => handleDeleteQuestion(row.id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="bg-red-500 text-white w-[100px] py-1  rounded ms-3"
                onClick={(e) => {
                  // e.preventDefault();
                  // handleEdit(row.id);
                  //handleDeleteQuestion(row.id);
                }}
              >
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const eee = listData.filter((item) => item.id === recordId)[0];
  }, [recordId]);

  return (
    <>
      {contextHolder}
      <Modal
        open={openModal}
        onOk={onClick}
        onCancel={onClick}
        footer={null}
        width={900}
        centered
      >
        <div>
          <p className="text-[20px] font-[500] text-[#4C4C4C]">
            {title} Interview Client
          </p>
          <p className="text-[12px] font-[500] text-[#A8A8A8] mb-[40px]">
            Enter your data below!
          </p>
          <div className=" h-[400px] overflow-auto">
            <Form
              name={title}
              form={instanceForm}
              colon={false}
              labelAlign="left"
              labelCol={{ span: 6 }}
              //style={{ maxWidth: 1000 }}
              onFinish={(props) =>
                getData("success", props, selectedRole, selectedAccount)
              }
              onFinishFailed={(props) =>
                getData("failed", props, selectedRole, selectedAccount)
              }
              autoComplete="off"
            >
              <Form.Item
                label="Position"
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  value={selectedRole}
                  onChange={(val) => setSelectedRole(val)}
                  showSearch
                  placeholder="Choose a Position"
                  loading={loadingMore}
                  onSearch={handleSearchRole}
                  optionFilterProp="children"
                  dropdownRender={(menu) => (
                    <div onWheel={handleScrollEventRole}>{menu}</div>
                  )}
                >
                  {listRole.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Account"
                name="account"
                rules={[
                  {
                    required: true,
                    message: "Please Select Account",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  value={selectedAccount}
                  onChange={(val) => setSelectedAccount(val)}
                  showSearch
                  placeholder="Choose an Account"
                  loading={loadingMore}
                  onSearch={handleSearchAccount}
                  optionFilterProp="children"
                  dropdownRender={(menu) => (
                    <div onWheel={handleScrollEventAccount}>{menu}</div>
                  )}
                >
                  {listAccount.map((account) => (
                    <Option key={account.id} value={account.id}>
                      {account.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="PIC TA"
                name="picTa"
                rules={[
                  {
                    message: "Enter PIC TA",
                  },
                ]}
              >
                <Input placeholder="Enter PIC TA" />
              </Form.Item>
              <Form.Item
                label="Interviewer Name"
                name="interviewerName"
              >
                <Input placeholder="Enter Interviewer Name" />
              </Form.Item>
              <Form.Item
                label="Project Name"
                name="projectName"
                rules={[
                  {
                    required: false,
                    message: "Enter Project Name",
                  },
                ]}
              >
                <Input placeholder="Enter Project Name" />
              </Form.Item>
              {title.toLowerCase() === "edit" && (
                <>
                  <div className="flex justify-between items-center mb-2">
                    <p className="mb-3 text-md">List Retro Question</p>
                    <Button
                      className="bg-red-500 radius-[7px] w-40 h-[30px]"
                      style={{ backgroundColor: "red" }}
                      onClick={(e) => {
                        setIdCourse(undefined);
                        setKeysRetro((prevVal) => prevVal + 1)
                        e.preventDefault();
                        setRecordId(() => "");
                        setQuestionEdit(() => "new"); // false == new
                        setQuestionOpen(true);
                      }}
                    >
                      <p className="text-[12px] font-[500] text-white">
                        {"Add"}
                      </p>
                    </Button>
                  </div>
                  <TableInterviewCandidate
                    dataSource={listData}
                    columns={columns}
                    isLoading={loadingTable}
                  />
                  <div className="w-full flex justify-center my-5">
                    <Pagination
                      current={page}
                      onChange={onChangePage}
                      pageSize={limit}
                      total={totalItem}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              )}
              {title.toLowerCase() !== "view" && (
                <Form.Item>
                  <div className="flex justify-center">
                    <Button
                      className="bg-red-500 radius-[7px] w-40 h-[30px]"
                      style={{ backgroundColor: "red" }}
                      type="primary"
                      htmlType="submit"
                    >
                      <p className="text-[12px] font-[500] text-white">
                        {dataEdit ? "Update" : "Create"}
                      </p>
                    </Button>
                  </div>
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      </Modal>
      {
        <RetroQuestionForm
          key={keysRetro}
          openModal={questionOpen}
          handleModal={() => {
            setQuestionOpen(() => false);
            //formQuestion.resetFields();
          }}
          retroId={dataEdit?.id}
          questionData={listData}
          recordId={recordId}
          formQuestion={formQuestion}
          listCourse={listCourse}
          handleSearch={handleSearchCourse}
          handleConfirm={(body) => {
            handleQuestion(body);
          }}
          loadingMore={loadingMore}
          action={questionEdit}
          listMatkul={listMatkul}
          loadingMoreMatkul={loadingMoreMatkul}
          loadingSearchMatkul={loadingSearchMatkul}
          handleScrollEventMatkul={handleScrollEventMatkul}
          handleSearchMatkul={handleSearchMatkul}
          onSelectCourse={handleSelectedCourse}
          idCourse={idCourse}
          selectedMatkul={selectedMatkul}
        />
      }
    </>
  );
};

export default ModalInterviewClient;
